import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import PortfolioFeed from "../components/portfolio-feed";
import Layout from "../components/layout";
import Seo from "../components/seo";

import employingWebConsultingBackgroundImage from "../images/backrounds/employing-web-consulting.jpg";
import patternHexGreenBackgroundImage from "../images/backrounds/pattern-hex-minotour-green.jpg";

import ConsultingIcon from "../icons/consulting.svg";
import PagesIcon from "../icons/pages.svg";
import CandidateIcon from "../icons/candidate.svg";
import EvaluatingIcon from "../icons/evaluating.svg";
import CustomerServiceIcon from "../icons/customer-service-help.svg";
import WebDesignIcon from "../icons/website-design.svg";
import UiUxDesignIcon from "../icons/ui-ux-setup.svg";
import BrandIdentityIcon from "../icons/brand-identity.svg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Why Companies Employing Web Consulting Will Get Better Results Than You"
        description="There is no question that a website is one of the most important pieces of a business’ marketing strategy."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${employingWebConsultingBackgroundImage})`,
            height: "30vw",
            minHeight: "140px",
          }}
        ></div>
      </section>
      <section className="p-b-6">
        <div className="container pb-6">
          <div className="row">
            <div className="col-12">
              <div className="border-bottom-primary-light border-top-primary-light card custom-cards mt-n4 mt-sm-n6 p-4 text-left">
                <h2>
                  Why Companies Employing Web Consulting Will Get Better Results Than You
                </h2>
                <p>
                  There is no question that a website is one of the most
                  important pieces of a business’ marketing strategy.  And not
                  just any website, but a professional site that is
                  well-organized, easy to navigate, and that yields results for
                  the target customers who will use it. With all of the
                  available tools, website builders, agencies, and freelancers,
                  how exactly does one achieve this? We’re going to dive into
                  all things web consulting, UX/UI and web design consulting.
                  We’ll explain how they differ, the benefits of each and how to
                  successfully incorporate them into your business strategy.
                </p>
                <p>
                  Then, we’ll show how to transform your business by giving it a
                  fresh, engaging online presence that blows away the
                  competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container py-6">
          <div className="justify-content-center row">
            <div className="col-12 col-lg-4">
              <h2>Web Design Consulting Services Defined</h2>
            </div>
            <div className="col-12 col-lg-5">
              <ul className="list-unstyled">
                <li>
                  <AnchorLink
                    className="d-block py-2"
                    to="#whatisdesignconsulting"
                  >
                    <div className="d-flex">
                      <ConsultingIcon
                        className="flex-shrink-0 pr-4 text-primary-light"
                        width={100}
                      />
                      <p className="text-dark">
                        What is website design consulting?
                      </p>
                    </div>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink className="d-block py-2" to="#difference">
                    <div className="d-flex">
                      <PagesIcon
                        className="flex-shrink-0 pr-4 text-primary-light"
                        width={100}
                      />
                      <p className="text-dark">
                        The difference between website design consultants and
                        website developers
                      </p>
                    </div>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink className="d-block py-2" to="#candidate">
                    <div className="d-flex">
                      <CandidateIcon
                        className="flex-shrink-0 pr-4 text-primary-light"
                        width={100}
                      />
                      <p className="text-dark">
                        Are You a Good Candidate for Web Consulting?
                      </p>
                    </div>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink className="d-block py-2" to="#ux-consultant">
                    <div className="d-flex">
                      <EvaluatingIcon
                        className="flex-shrink-0 pr-4 text-primary-light"
                        width={100}
                      />
                      <p className="text-dark">
                        What’s a user interface and user experience consultant
                        (UI/UX)?
                      </p>
                    </div>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink className="d-block py-2" to="#how729">
                    <div className="d-flex">
                      <CustomerServiceIcon
                        className="flex-shrink-0 pr-4 text-primary-light"
                        width={100}
                      />
                      <p className="text-dark">
                        How 729 Solutions’ Web Design Consulting Services Can
                        Help My Company Achieve Better Results
                      </p>
                    </div>
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundImage: `url(${patternHexGreenBackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 py-6 text-center">
              <h2 className="text-white">
                Let Us Help You Prove the Roi of Good Web Design to Your Decision-maker.
              </h2>
              <div className="row">
                <div className="col-12">
                  <p className="text-white">
                    Get our free guide, How to Convince your Boss to Invest in a
                    Web Design Consultant, and learn about our three-part
                    framework will provide you with concrete guidance that’ll
                    have you persuading your leaders in no time.
                  </p>
                  <CTA
                    className="btn btn-primary-light"
                    href="http://info.729solutions.com/how-to-convince-your-boss-web-design-consultant-roi"
                    text="Learn More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="whatisdesignconsulting"
        className="bg-gray-100 border border-left-0 border-right-0"
      >
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-lg-3 text-center pb-5">
              <div className="bg-gray-200 d-inline-block p-4">
                <ConsultingIcon width={120} className="text-primary-light" />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <h2>What Is Website Design Consulting?</h2>
              <p>
                To answer this question, we first need to have an understanding
                of what kind of design we’re talking about. Don’t just think of
                coordinating colors and pretty images (though these are
                important, too).
              </p>
              <p>
                A well-designed project (be it a website, software integration,
                datasheet, logo, postcard…etc.) is paramount for the target user
                to quickly and easily understand its purpose.
              </p>
              <p>
                Poorly, or non-designed, projects can be confusing to look at,
                and typically leave the user unsure where to start. Confusion
                doesn’t convert and in our competitive online world, this means
                your poorly designed site is losing your business.
              </p>
              <p>
                <strong>
                  A good design has these things that fit into every aspect of
                  every project:
                </strong>
              </p>
              <div className="row">
                <div className="col-12 col-lg-4 pt-3">
                  <p>
                    <strong>Intuitive UX/UI:</strong>
                  </p>
                  <p>
                    Websites should clearly show the user where to start. Links
                    and button locations and purpose should be obvious. Good
                    design is usually “invisible,” everything is intentional.
                  </p>
                </div>
                <div className="col-12 col-lg-4 pt-3">
                  <p>
                    <strong>Visually Welcoming:</strong>
                  </p>
                  <p>
                    All projects should be inviting and accessible. Should users
                    feel happy? Sad? Scared? Safe? All of this can be
                    accomplished by design.
                  </p>
                </div>
                <div className="col-12 col-lg-4 pt-3">
                  <p>
                    <strong>Visually Competitive:</strong>
                  </p>
                  <p>
                    How do we ensure our client stands out in a crowd from their
                    competition? Their project must look beautiful, easy to use,
                    and highlight what makes them unique.
                  </p>
                </div>
                <div className="col-12 pt-5">
                  <h3>What does a website design consultant do?</h3>
                  <p>
                    So, what exactly do web design consultants do? Let’s take a
                    quick look into what they do and how they can help your
                    business grow and thrive.
                  </p>
                  <p>
                    A solid web design consultant solves complex problems for
                    their clients and acts as a true partner in their business
                    growth. They will start by opening a dialogue with your
                    business to identify your goals and create a roadmap to site
                    success.
                  </p>
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Website design consulting services specialize in
                        creating user-friendly sites that reliably attract
                        visitors and engage them with your content.
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Your web design consultant not only helps you build site
                        traffic and avoid technical glitches but also delights
                        your audience with easy usability.
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Professional, expert web design shows your visitors
                        exactly what they need at exactly the right moments. It
                        avoids confusing, unnecessary distractions so you can
                        focus on driving your message home.
                      </span>
                    </li>
                  </ul>
                  <p>
                    Through collaboration, you and your website consultant will
                    design a site that looks terrific and functions flawlessly.
                  </p>
                  <h3>How a website design consultant can save you money?</h3>
                  <p>
                    So many people have great ideas, but few website developers
                    or designers have a broad enough base of knowledge to get
                    you through:
                  </p>
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        understanding your users,
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        mapping out how they prefer to navigate virtual
                        experiences,
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        detailing out the technical pieces of the project,
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        and estimate a true overall effort to execute from start
                        to finish
                      </span>
                    </li>
                  </ul>
                  <div
                    className="bg-gray-200 border float-right mb-4 ml-4 p-3"
                    style={{ maxWidth: "96%", width: "200px" }}
                  >
                    <StaticImage
                      src="../images/team/rob-fife.png"
                      alt="Headshot of Rob Fife"
                    />
                    <p className="text-center pt-3">Rob Fife</p>
                  </div>
                  <p>
                    We spoke to our founder to learn about the company’s
                    decision to place such importance on including website
                    design consultants in our project planning process. He’s
                    been at this since the 1990s and has worked on countless
                    projects with every type of developer, engineer, and
                    designer you can imagine. Here’s what he has to say about
                    it:
                  </p>
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Developers can tend to over-engineer things, which tends
                        to cause more problems than solutions
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        The web design consulting process puts the appropriate
                        guard rails in place
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Designers design for elegance – to ensure that we
                        develop things that help users get to where they need to
                        go with as few steps as possible
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        As we’ve said before, it’s not just about pretty
                        pictures. The web consultant actually becomes the drum
                        major – the rest of the project team is all marching to
                        that beat
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        The design process is essential to good engineering
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        The web consultant ensures that we can fully deliver on
                        your current needs, within your current budget, while
                        also planning for future iterations you will need
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        The designer can give us both visuals and workflows –
                        which became the blueprint for the scoping process, and
                        directions for the engineering
                      </span>
                    </li>
                  </ul>
                  <p>
                    All of this to say, web design consultants ensure that we do
                    not over-engineer or under-engineer. This saves time in
                    development (saving time is saving money) while also
                    ensuring your project gets everything it needs and nothing
                    more (or less!).
                  </p>
                  <h3>
                    How website design consulting helps your business thrive?
                  </h3>
                  <p>
                    When you have world-class web design consulting, the design
                    of your site will naturally and organically draw in your
                    target audience. Your website consultant keeps track of all
                    the latest web design best practices so you don’t have to.
                  </p>
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Develop a site that meets the latest internet standards
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Attract attention from search engines
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Bring insights from hundreds of other projects
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Create a site that’s a pleasure to use
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Help build loyal customers for your business
                      </span>
                    </li>
                  </ul>
                  <p>
                    <strong>Why is all of this so important?</strong>
                  </p>
                  <p>
                    Consider this fact: The average person now has a{" "}
                    <a
                      href="https://time.com/3858309/attention-spans-goldfish/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      shorter attention span
                    </a>{" "}
                    than a goldfish.
                  </p>
                  <p>
                    <strong>Goldfish: 9 seconds. Human: 8 seconds.</strong>
                  </p>
                  <StaticImage
                    src="../images/why-companies-employing-web-consulting/729-attention-span.png"
                    className="mb-3"
                    layout="constrained"
                  />
                  <p>
                    Good design keeps everyone happy: you, your audience,
                    Google, and all the rest. And that’s a very big deal. After
                    all, the Google algorithm changes a mere 3,000 times a year,
                    which is just slightly challenging to track on your own.
                  </p>
                  <p>
                    Your website consultant understands the importance of a
                    well-designed website and will help you create a site that
                    covers all{" "}
                    <a
                      href="https://en.wikipedia.org/wiki/Web_design"
                      target="_blank"
                      rel="noreferrer"
                    >
                      key areas
                    </a>{" "}
                    of web design:
                  </p>
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Graphic design for the web
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">Interface design</span>
                    </li>
                    <li>
                      <span className="text-dark">Authoring</span>
                    </li>
                    <li>
                      <span className="text-dark">User experience design</span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Search engine optimization (SEO)
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Search engine optimization (SEO) Even helping decide on
                        the right platform (WordPress, Shopify, hard
                        code/languages)
                      </span>
                    </li>
                  </ul>
                  <p>
                    A web design consultant helps you handle all the little
                    details while still seeing the big picture of your online
                    presence. When you add a web design consultant to your team,
                    you’ll instantly gain expert insight from somebody who’s
                    seen it all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="difference">
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-lg-3 text-center pb-5">
              <div className="bg-gray-100 d-inline-block p-4">
                <PagesIcon width={120} className="text-primary-light" />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <h2>
                The difference between website design consultants and website developers
              </h2>
              <p>
                It’s important to understand what the difference is between
                hiring a website design consultant and hiring a website
                developer. The two roles are actually entirely different and
                each handles different aspects of the website development
                process.
              </p>
              <p>
                As discussed above, website design consultants focus on the
                overall website strategy and develop a clear design and
                technical roadmap, steps that need to be taken to create a high
                performing website.
              </p>
              <p>
                Website developers implement the technical aspects of the
                roadmap, focusing primarily on coding a functioning website that
                meets requirements set by you and your website consultant.
              </p>
              <p>
                The roadmap becomes an important tool for a web developer, by
                helping them to avoid over-engineering. This ensures that you
                are getting everything you need and not more or less.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="candidate"
        className="bg-gray-100 border border-left-0 border-right-0"
      >
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-lg-3 text-center pb-5">
              <div className="bg-gray-200 d-inline-block p-4">
                <CandidateIcon width={120} className="text-primary-light" />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <h2>Are you a good candidate for web consulting?</h2>
              <p>
                If you’re wondering whether your company really needs web design
                services, here’s something to consider. Most websites, even the
                best ones, have glitches and outdated design details that are
                begging to be addressed.
              </p>
              <p>
                Companies rarely do a complete redesign, but they are constantly
                making site enhancements to better serve their customers. How
                about your company? Are you relentlessly improving your site?
              </p>
              <p>
                If not, read on. Our web consultants shared some of the most
                common problems we’ve seen on our clients’ sites that make us
                cringe a bit and suggest website design consulting services.
              </p>
              <div className="row mb-3">
                <div className="col-12 col-lg-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Your site hasn’t been redesigned in years
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        You’re not attracting fresh business
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        The site attracts but doesn’t convert
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Visitor time-on-site is very low
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        You don’t have a web presence at all!
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Your business is growing and has outgrown its current
                        site
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Your business model is changing
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        You’re an exclusively online business
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">Has slow-loading pages</span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Glitches and error messages
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Old-fashioned, outdated design
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Loud and distracting elements
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">Hard-to-read content</span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Lack of mobile-friendliness
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Missing/low-quality photos
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">No modern SEO</span>
                    </li>
                  </ul>
                </div>
              </div>

              <p>
                A website design service will help you address these critical
                issues and create a seamless site that converts passing interest
                into loyal customers. Before hiring a website consultant, ask to
                see their portfolio and/or case studies that show their
                expertise.
              </p>
              <CTA
                className="btn btn-primary-light"
                href="/contact-us"
                text="I Think I'm A Candidate For Web Consulting Services"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="ux-consultant">
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-lg-3 text-center pb-5">
              <div className="bg-gray-100 d-inline-block p-4">
                <EvaluatingIcon width={120} className="text-primary-light" />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <h2>
                What’s a User Interface and User Experience Consultant (UI/UX)?
              </h2>
              <p>
                UI and UX help you get the most out of your web design dollars,
                so let’s get more familiar with this part of the web design
                consulting process. Tapping into the web consultants expertise
                in user interface (UI) and user experience (UX) consulting is
                one of the keys to providing great website design consulting
                services.
              </p>
              <p>
                User experience consultants are trained in the art of the
                user-first approach, which focuses directly on the needs of your
                site visitors. Their knowledge is based on{" "}
                <a
                  href="https://uxdesign.cc/hey-psychology-students-consider-ux-as-your-future-job-ba81aea232b9"
                  target="_blank"
                  rel="noreferrer"
                >
                  human psychology
                </a>{" "}
                and years of studying and keeping up with changes in how people
                use the internet.
              </p>

              <p>
                These web experience experts save development time by designing
                things to work smoothly right from the start.
              </p>
              <h3>Eight ways a UI/UX consultant can help your business</h3>
              <p>
                A skilled UI/UX consultant gains deep insight into your business
                and its target audience. With their user experience consulting
                background, they can work their magic on your site.
              </p>
              <ol className="text-primary">
                <li>
                  <span className="text-dark">
                    Minimize site errors and glitches.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Enhance site performance and engagement.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Reduce lead time to launch new pages and content.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Perform site A/B testing for decision-making.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Limit your website spending and maintenance.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Foresee site issues, so your audience never sees them.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Give you a realistic user perspective.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Analyze and share metrics that help your business grow.
                  </span>
                </li>
              </ol>
              <h3>Can your business benefit from UX/UI consulting?</h3>
              <p>
                If your business falls into one of the following categories,
                you’re a great candidate for UX/UI consulting services.
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    You have a high number of site visitors, but a low
                    time-on-site. This indicates they’re coming in droves, but
                    you’re driving them away.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Users go straight from your main page to the “help” or
                    “contact” link, indicating they’re giving up and hoping for
                    answers from humans.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    You’re receiving negative feedback about your site through
                    surveys, forms, or social media.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Your site isn’t achieving its desired action through
                    call-to-action (CTA) buttons and links.
                  </span>
                </li>
              </ul>
              <p>
                A website design service will help you address these critical
                issues and create a seamless site that converts passing interest
                into loyal customers. Before hiring a website consultant, ask to
                see their portfolio and/or case studies that show their
                expertise.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 border border-left-0 border-right-0">
        <div id="how729" className="container py-6">
          <div className="row">
            <div className="col-12 col-lg-3 text-center pb-5">
              <div className="bg-gray-200 d-inline-block p-4">
                <CustomerServiceIcon
                  width={120}
                  className="text-primary-light"
                />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <h2>
                How 729 Solutions’ Web Design Consulting Services Can Help My Company Achieve Better Results
              </h2>
              <p>
                When it comes to web design consulting and UI/UX consulting, 729
                Solutions has over a decade of experience helping businesses
                across a wide range of industries to improve the performance of
                their websites.
              </p>
              <p>
                When you hire 729 solutions as your web consulting firm, you are
                taking a step that places you in the ranks of the world’s most
                successful companies that are passionate about growing their
                business and recognize the importance of a users site experience
                helps them to get what they want out of every interaction.
              </p>
              <p>
                With 729 web consulting services, you don’t get just one person,
                You get a team of experts that partner with you, look at
                everything about your business, identify hidden issues, and set
                priorities and help you define the meaning of success for your
                project.
              </p>
              <p>
                <strong>Your 729 web consultant will tackle tasks like:</strong>
              </p>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Identifying your target audience
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Creating site{" "}
                        <Link to="/buyer-personas">user personas</Link>
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Create a well structured sitemap
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Creating your website content strategy
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-6">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Making specific content recommendations
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        End-to-end site design and development
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Site tracking and analytics
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Strategic planning to boost your business
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundImage: `url(${patternHexGreenBackgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 py-6 text-center">
              <h2 className="text-white">
                Let us help you prove the ROI of good web design to your decision-maker.
              </h2>
              <div className="row">
                <div className="col-12">
                  <p className="text-white">
                    Get our free guide, How to Convince your Boss to Invest in a
                    Web Design Consultant, and learn about our three-part
                    framework will provide you with concrete guidance that’ll
                    have you persuading your leaders in no time.
                  </p>
                  <CTA
                    className="btn btn-primary-light"
                    href="http://info.729solutions.com/how-to-convince-your-boss-web-design-consultant-roi"
                    text="Learn More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6 mb-6">
          <div className="row text-center">
            <div className="col">
              <Link to="/web-design-consulting-services/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="12.96"
                      x2="87.72"
                      y1="51.35"
                      y2="51.35"
                    />
                    <path
                      d="M131,45.45v80.63H13V23.82H115.3"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="28.7"
                      x2="36.56"
                      y1="36.98"
                      y2="36.98"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="48.36"
                      x2="56.23"
                      y1="36.98"
                      y2="36.98"
                    />
                    <path
                      d="M71.67,67.38,119.17,20A7,7,0,0,1,129,20h0a7,7,0,0,1,0,9.84h0L81.5,77.21"
                      fill="none"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M78,70.86a12.88,12.88,0,0,0-18.22,0h0C55,75.7,54.26,94.62,54.26,94.62S73.18,93.93,78,89.09a12.88,12.88,0,0,0,0-18.23Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      x1="28.7"
                      x2="115.23"
                      y1="109.75"
                      y2="109.75"
                    />
                  </g>
                </svg>
                <p>Website Design Consulting</p>
              </Link>
            </div>
            <div className="col">
              <Link to="/user-experience-consultant-services/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <rect
                      fill="none"
                      height="70.04"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.07 0.06) rotate(-0.09)"
                      width="48.25"
                      x="17.49"
                      y="12.02"
                    />
                    <rect
                      fill="none"
                      height="38.91"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.17 0.06) rotate(-0.09)"
                      width="48.25"
                      x="17.59"
                      y="94.51"
                    />
                    <rect
                      fill="none"
                      height="38.91"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.05 0.15) rotate(-0.09)"
                      width="48.25"
                      x="78.16"
                      y="11.93"
                    />
                    <rect
                      fill="none"
                      height="70.04"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                      transform="translate(-0.15 0.15) rotate(-0.09)"
                      width="48.25"
                      x="78.26"
                      y="63.29"
                    />
                  </g>
                </svg>
                <p>UI/UX Design Consulting</p>
              </Link>
            </div>
            <div className="col">
              <Link to="/branding-design-logo-design/">
                <svg
                  className="text-primary-light mb-3"
                  height={90}
                  viewBox="0 0 144 144"
                  width={90}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M17.81,42.14l0-24.06,24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M126.1,42l0-24.06L102,18"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M17.9,102.3l0,24.06,24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M126.19,102.14l0,24.06-24.06,0"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <path
                      d="M15.85,72.23s24-32.12,56.1-32.17,56.2,32,56.2,32-24,32.12-56.1,32.17S15.85,72.23,15.85,72.23Z"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                    <circle
                      cx="72"
                      cy="72.14"
                      fill="none"
                      r="16.66"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="3"
                    />
                  </g>
                </svg>
                <p>Branding & Identity Design Consulting</p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary pb-6">
        <div className="container">
          <div className="row">
            <div className="col mt-n6">
              <div className="card custom-cards card-border">
                <div className="card-body">
                  <h3>About 729 Solutions</h3>
                  <p>
                    729 is a team of hard-working designers, developers, project
                    managers, and solutions architects. We are problem solvers
                    who come up with new ways to make technology faster, safer,
                    and easier. We are builders who create experiences and
                    products that people love. We integrate complex systems in
                    the simplest ways possible. We help fast-growing companies
                    and their customers do more.
                  </p>
                  <p>
                    We’ve been engineering amazing technology for 15 years and
                    we want to do it for you.
                  </p>
                  <CTA
                    href="/contact-us"
                    className="btn btn-primary"
                    text="Let's Chat About Your Project"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <BlogFeed />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
